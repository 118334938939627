import React from "react";
import { Redirect, Route, Switch, useLocation, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import { LayoutContextProvider } from "../components/SplashScreen";
import HomePage from "../pages/home/HomePage";
import LogoutPage from "../pages/auth/Logout";
import AuthPage from "../pages/auth/AuthPage";
import * as routerHelpers from "../router/RouterHelpers";
import Layout from "../components/layout/Layout";

export const Routes = withRouter(({ history }) => {
	const lastLocation = useLastLocation();

	routerHelpers.saveLastLocation(lastLocation);

	const { isAuthorized, menuConfig, userLastLocation } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null,
			userLastLocation: routerHelpers.getLastLocation(),
		}),
		shallowEqual
	);

	return (
		<LayoutContextProvider history={history} menuConfig={menuConfig}>
			<Switch>
				{/* {!isAuthorized && useLocation().pathname === '/password/forgot' ?
					<Route path="/password/forgot" component={ForgotPassword} />
					: null
				}

				{!isAuthorized && useLocation().pathname === '/password/reset' ?
					<Route path="/password/reset" component={ResetPassword} />
					: null
				} */}

				{!isAuthorized ? (
					<AuthPage />
				) : (
					<Redirect from="/login" to={userLastLocation} />
				)}

				{/* <Route path="/error" component={ErrorsPage} /> */}
				<Route path="/logout" component={LogoutPage} />

				{!isAuthorized ? (
					<Redirect to="/login" />
				) : (
					<Layout>
						<HomePage userLastLocation={userLastLocation} />
					</Layout>
				)}
			</Switch>
		</LayoutContextProvider>
	);
});
