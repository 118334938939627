let url = "";

if (process.env.NODE_ENV === "development") {
    url = 'http://192.168.15.40:8000';
} else {
    url = "https://api.behuve.com";
}

export const Constants = {
    baseUrl: url + "/api/web",
    url,
};
