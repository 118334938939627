import axios from "axios";
import { Constants } from "../utils/Constants";

export function list(events) {
    return axios.post(`${Constants.baseUrl}/documento/list`, events);
}

export function simpleList(events) {
    return axios.post(`${Constants.baseUrl}/documento/simpleList`, events);
}

export function get(id) {
    return axios.get(`${Constants.baseUrl}/documento/${id}`);
}

export function listMembros(events) {
    return axios.post(`${Constants.baseUrl}/documento/listMembros/${events.id}`, events);
}

export function listComentarios(events) {
    return axios.post(`${Constants.baseUrl}/documento/listComentarios/${events.id}`, events);
}

export function storeMembro(events) {
    return axios.post(`${Constants.baseUrl}/documento/storeMembro`, events);
}

export function massDelete(events) {
    return axios.post(`${Constants.baseUrl}/documento/massDelete`, events);
}

export function massDeleteComentarios(events) {
    return axios.post(`${Constants.baseUrl}/documento/massDeleteComentarios`, events);
}

export function store(events) {
    return axios.post(`${Constants.baseUrl}/documento`, events);
}

export function destroy(id) {
    return axios.delete(`${Constants.baseUrl}/documento/${id}`);
}

export function update(events, id) {
    return axios.post(`${Constants.baseUrl}/documento/${id}`, events);
}

export function switchInteracao(events, id) {
    return axios.post(`${Constants.baseUrl}/documento/switchInteracao/${id}`, events);
}

export function listRespostasFormulario(events) {
    return axios.post(`${Constants.baseUrl}/documento/listRespostas/${events.id}`, events);
}

export function getRealidadeAumentada(id){
    return axios.get(`${Constants.baseUrl}/documento/realidadeAumentada/${id}`);
}