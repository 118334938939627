import axios from "axios";
import { Constants } from "../utils/Constants";

export function getDataTable(events) {
    return axios.post(`${Constants.baseUrl}/user/list`, events);
}

export function create(form) {
    return axios.post(`${Constants.baseUrl}/user`, form);
}

export function checarEmailCpf(form) {
    return axios.post(`${Constants.baseUrl}/user/emailCpf`, form);
}

export function get(id) {
    return axios.get(`${Constants.baseUrl}/user/${id}`);
}

export function update(form, id) {
    return axios.post(`${Constants.baseUrl}/user/update/${id}`, form);
}

export function destroy(id) {
    return axios.delete(`${Constants.baseUrl}/user/${id}`);
}

export function acessos(data) {
    return axios.post(`${Constants.baseUrl}/user/acessos`, data);
}

export function adicionarAcesso(data) {
    return axios.post(`${Constants.baseUrl}/user/adicionarAcesso`, data);
}

export function removerAcesso(data) {
    return axios.post(`${Constants.baseUrl}/user/removerAcesso`, data);
}

export function getListaSolicitacoes(){
    return axios.get(`${Constants.baseUrl}/user/solicitacoes`);
}

export function responderSolicitacao(data){
    return axios.post(`${Constants.baseUrl}/user/respostaSolicitacao`, data);
}