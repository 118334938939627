import React, { useEffect, useState } from "react";
import styles from '../../../styles/dashboard/Documentos.module.css';
import BetterCircularProgress from "../../../components/BetterCircularProgress";
import { list as listDocumentos } from "../../../crud/documento.crud";
import { useSelector } from 'react-redux';
import HeaderSearch from "../../../components/HeaderSearch";
import { Link } from "react-router-dom";
import useDebounce from "../../../components/UseDebounce";
import { formatarConteudo } from '../../../utils/Utils'

export default function DocumentosPage() {
	const user = useSelector((state) => state.auth.user);
	const [loading, setLoading] = useState(true);
	const [documentosList, setDocumentosList] = useState([]);
	const [documentoFiltro, setDocumentoFiltro] = useState('');
	const [urlImage, setUrlImage] = useState('/images/no-image.png');
	const [urlAvatar, setUrlAvatar] = useState('/images/no-user.png');

	const debouncedFiltro = useDebounce(documentoFiltro, 500);

	useEffect(() => {
		setLoading(true);

		handler(debouncedFiltro);

	}, [debouncedFiltro]);

	const handler = (filtro = null) => {
		const dataSearch = {
			can_id_autor: user.id
		};
		setDocumentoFiltro(filtro);

		if (filtro) {
			dataSearch.globalFilter = filtro;
		}

		listDocumentos(dataSearch).then(res => {
			setDocumentosList(res.data.data);
			setLoading(false);
		}).catch(err => {
			console.log(err);
			setLoading(false);
		});
	};

	const handleSearch = (event) => {
		setDocumentoFiltro(event);
	};

	return (
		<div className={styles.mainContainer} >
			<div className={styles.container} >
				<div className={styles.containerHeader} >
					<div>
						<h1 className={styles.titleHeader} >Documentos</h1>
						<p className={styles.subtitleHeader}> {`${documentosList.length} documentos`}</p>
					</div>

					<div className={styles.containerHeaderFiltro} >
						<HeaderSearch type="text" placeholder="Pesquisar documentos"
							onChange={(e) => handleSearch(e.target.value)}
							value={documentoFiltro} />

						<Link className='button-fill' to='../documento/new' >
							<i style={{ fontSize: '18px' }} className='ri-add-line' ></i>Adicionar
						</Link>
					</div>
				</div>

				<BetterCircularProgress loading={loading}>
					{documentosList?.length > 0 ?
						(<div className={styles.containerBody} >
							{documentosList.map((obj, i) => {
								return (
									<div key={`documento_${i}`} className={styles.cardDocumento} >
										<div className={styles.cardImg} style={{ backgroundImage: `url(${obj?.imagem_capa?.url ?? urlImage})`, backgroundPosition: 'center', backgroundSize: 'cover' }} ></div>

										<div className={styles.cardBody} >
											<div>
												<div className={styles.containerAvatar} style={{ backgroundImage: `url(${obj?.imagem?.url ?? urlAvatar})` }} />
												<div className={styles.headerCard} style={{ marginTop: '24px' }} >
													<h1>{obj.doc_nome}</h1>
												</div>

												<p>{formatarConteudo(obj.doc_descricao, 45)}</p>
											</div>

											<Link className='button-outline' to={`../documento/show/${obj.id}`} >
												<i className="ri-eye-line" style={{ marginRight: '8px' }} ></i>Ver Documento
											</Link>
										</div>
									</div>
								)
							})}
						</div>)
						:
						<>
							<h3 style={{ textAlign: 'center', fontWeight: '400', color: '#6A6B74', fontSize: '24px', paddingTop: '80px' }}>Nenhum documento encontrado!</h3>
						</>
					}
				</BetterCircularProgress>
			</div>
		</div>
	);
}
