import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses, menuClasses } from 'react-pro-sidebar';
import { Link, useLocation } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { useMediaQuery } from 'react-responsive';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
		width: '200px'
	},
})((props) => (
	<MuiMenu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

export default function SideMenu() {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

	const location = useLocation();
	const [sidebarOpen, setSidebarOpen] = useState(true);

	useEffect(() => {
		if (isMobile) {
			setSidebarOpen(false);
		}
	}, [isMobile]);

	const menuItemsDashboard = [
		{
			icon: 'ri-file-text-line',
			label: 'Projetos',
			page: 'dashboard/projetos',
		},
		{
			icon: 'ri-at-line',
			label: 'Meus canais',
			page: 'dashboard/canais',
		},
		{
			icon: 'ri-team-line',
			label: 'Meus contatos',
			page: 'dashboard/contatos',
		},
		{
			icon: 'ri-message-2-line',
			label: 'Conversas',
			page: 'dashboard/conversas',
		},
	];

	const menuItemRecursos = [
		{
			icon: 'ri-instance-line',
			label: 'Documentos',
			page: 'dashboard/documentos',
		},
		{
			icon: 'ri-pie-chart-line',
			label: 'Gráficos',
			page: 'graficos',
		},
		{
			icon: 'ri-image-2-line',
			label: 'Imagens',
			page: 'imagens',
		},
		{
			icon: 'ri-video-on-line',
			label: 'Vídeos',
			page: 'videos',
		},
		{
			icon: 'ri-box-3-line',
			label: 'Objetos 3D',
			page: 'objetos',
		},
		{
			icon: 'ri-text',
			label: 'Textos',
			page: 'textos',
		},
		{
			icon: 'ri-survey-line',
			label: 'Formulários',
			page: 'formularios',
		},
	];

	const menuItemFerramentas = [
		{
			icon: 'ri-goggles-line',
			label: 'Apresentação AR',
			page: 'apresentacao',
		},
	]

	const isCurrentPage = (page) => {
		if (location.pathname == page) return true;

		return false;
	};

	const SubMenu = ({ subMenuItems, title, subtitle, addMarginTop }) => {
		return (
			<>
				<div style={{ padding: '0 16px', marginBottom: '16px', marginTop: addMarginTop ? '24px' : '0' }}>
					{sidebarOpen ?
						<>
							<p style={{
								fontFamily: 'Poppins, Helvetica, sans-serif !important',
								fontWeight: 500,
								fontSize: '12px',
								lineHeight: '18px',
								marginBottom: 0
							}}>{title}</p>

							<p style={{
								fontFamily: 'Poppins, Helvetica, sans-serif !important',
								fontWeight: 400,
								fontSize: '10px',
								lineHeight: '15px',
								marginBottom: 0
							}}>{subtitle}</p>
						</>
						: null
					}
				</div>

				<Menu renderExpandIcon={() => null}>
					{subMenuItems.map((obj, i) => {
						return (
							<MenuItem key={`dashboard_${i}`}
								rootStyles={{
									[`.${menuClasses.button}`]: {
										padding: sidebarOpen ? '0 !important' : '14px !important',
										height: '53px !important',
										padding: sidebarOpen ? '0 16px' : '0',
									},
									[`.${menuClasses.label}`]: {
										alignItems: 'center',
										justifyContent: sidebarOpen ? 'flex-start' : 'center',
										display: 'flex',
										padding: sidebarOpen ? '16px' : '8px',
										fontFamily: 'Poppins, Helvetica, sans-serif !important',
										borderRadius: '50%',
										backgroundColor: isCurrentPage(`/${obj.page}`) ? sidebarOpen ? 'rgba(0,0,0,0)' : '#FFEDB3' : 'rgba(0,0,0,0)',
										color: isCurrentPage(`/${obj.page}`) ? '#F8B133' : '#1B1C1F',
									},
								}}
								component={<Link to={`/${obj.page}`} />}
							>
								<i className={`${obj.icon}`} style={{ color: isCurrentPage(`/${obj.page}`) ? sidebarOpen ? '#F8B133' : '#1B1C1F' : '#1B1C1F', fontSize: '20px', marginRight: sidebarOpen ? '8px' : '0' }} ></i>{sidebarOpen ? obj.label : ''}
							</MenuItem>
						)
					})}
				</Menu>
			</>
		)
	};

	return (
		<Sidebar collapsed={!sidebarOpen} collapsedWidth='80px' width="243px"
			rootStyles={{
				backgroundColor: '#fff',
				[`.${sidebarClasses.container}`]: {
					backgroundColor: '#FFF',
				},
			}}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: sidebarOpen ? 'space-between' : 'center', marginBottom: '24px', cursor: 'pointer', padding: sidebarOpen ? '24px' : '24px' }}
				onClick={() => setSidebarOpen(!sidebarOpen)}>
				{sidebarOpen ?
					<img src="/images/logo-dark.png" width={72} alt="Logo" />
					: null
				}

				<i style={{ fontSize: '24px' }} className={sidebarOpen ? "ri-menu-fold-fill" : "ri-menu-unfold-fill"}></i>
			</div>

			<SubMenu subMenuItems={menuItemsDashboard} title='Dashboard' subtitle='Informações globais' />

			<SubMenu subMenuItems={menuItemRecursos} title='Recursos' subtitle='Seus recursos pessoais' addMarginTop />

			<SubMenu subMenuItems={menuItemFerramentas} title='Ferramentas' subtitle='Ferramentas' addMarginTop />
		</Sidebar>
	);
}