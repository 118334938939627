import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import SideMenu from "./SideMenu";
import Header from "./Header";
import { useMediaQuery } from 'react-responsive';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
		width: '200px'
	},
})((props) => (
	<MuiMenu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

export default function Layout({ children }) {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
			{/* {isMobile ? null : <SideMenu />} */}
			<SideMenu />

			<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
				<Header />

				<div style={{ height: '100%', width: '100%' }}>
					{children}
				</div>
			</div>
		</div>
	);
}
