import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../components/SplashScreen";
import DocumentosPage from "./dashboard/DocumentoPage";

// Dashboard routes
const ProjetosPage = lazy(() => import("./dashboard/ProjetosPage"));
const CanaisPage = lazy(() => import("./dashboard/CanaisPage"));
const ConversasPage = lazy(() => import("./dashboard/ConversasPage"));
const ContatosPage = lazy(() => import("./dashboard/ContatosPage"));

// User routes
const UserShow = lazy(() => import("./user/UserShow"));
const UserEdit = lazy(() => import("./user/UserEdit"));
const UserSolicitacoes = lazy(() => import("./user/UserSolicitacoes"))

// Canais routes
const CanalNew = lazy(() => import("./canal/CanalNew"));
const CanalShow = lazy(() => import("./canal/CanalShow"));
const CanalEdit = lazy(() => import("./canal/CanalEdit"));

// Documento routes
const DocumentoNew = lazy(() => import("./documento/DocumentoNew"));
const DocumentoShow = lazy(() => import("./documento/DocumentoShow"));
const DocumentoEdit = lazy(() => import("./documento/DocumentoEdit"));
const DocumentoRealidadeAumentada = lazy(() => import("./documento/DocumentoRealidadeAumentada"));

// Grafico routes
const GraficoPage = lazy(() => import("./grafico/GraficoPage"));
const GraficoEdit = lazy(() => import("./grafico/GraficoEdit"));
const GraficoNew = lazy(() => import("./grafico/GraficoNew"));

// Imagem routes
const ImagemPage = lazy(() => import("./imagem/ImagemPage"));
const ImagemEdit = lazy(() => import("./imagem/ImagemEdit"));
const ImagemNew = lazy(() => import("./imagem/ImagemNew"));

// Vídeo routes
const VideoPage = lazy(() => import("./video/VideoPage"));
const VideoEdit = lazy(() => import("./video/VideoEdit"));
const VideoNew = lazy(() => import("./video/VideoNew"));

// Objeto routes
const ObjetoPage = lazy(() => import("./objeto/ObjetoPage"));
const ObjetoEdit = lazy(() => import("./objeto/ObjetoEdit"));
const ObjetoNew = lazy(() => import("./objeto/ObjetoNew"));

// Texto routes
const TextoPage = lazy(() => import("./texto/TextoPage"));
const TextoEdit = lazy(() => import("./texto/TextoEdit"));
const TextoNew = lazy(() => import("./texto/TextoNew"));

// Formulário routes
const FormularioPage = lazy(() => import("./formulario/FormularioPage"));
const FormularioNew = lazy(() => import("./formulario/FormularioNew"));
const FormularioEdit = lazy(() => import("./formulario/FormularioEdit"));

export default function HomePage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Redirect exact from="/" to="/dashboard/projetos" />
				<Redirect exact from="/dashboard" to="/dashboard/projetos" />
				<Redirect from="/login" to="/dashboard/projetos" />
				<Redirect exact from="/login" to="/dashboard/projetos" />
				<Redirect exact from="/register" to="/dashboard/projetos" />
				<Redirect exact from="/esqueceu-senha" to="/dashboard/projetos" />

				{/* Dasboard routes */}
				<Route path="/dashboard/projetos" component={ProjetosPage} />
				<Route path="/dashboard/canais" component={CanaisPage} />
				<Route path="/dashboard/conversas" component={ConversasPage} />
				<Route path="/dashboard/contatos" component={ContatosPage} />
				<Route path="/dashboard/documentos" component={DocumentosPage} />

				{/* User routes */}
				<Route path="/user/show/:id" component={UserShow} />
				<Route path="/user/edit/:id" component={UserEdit} />
				<Route path="/user/solicitacoes" component={UserSolicitacoes} />

				{/* CanalRoutes */}
				<Route path="/canal/new" component={CanalNew} />
				<Route path="/canal/show/:id" component={CanalShow} />
				<Route path="/canal/edit/:id" component={CanalEdit} />

				{/* DocumentoRoutes */}
				<Route path="/documento/new" component={DocumentoNew} />
				<Route path="/documento/show/:id" component={DocumentoShow} />
				<Route path="/documento/edit/:id" component={DocumentoEdit} />
				<Route path="/documento/realidade-aumentada/:id" component={DocumentoRealidadeAumentada} />

				{/* GraficoRoutes */}
				<Route exact path="/graficos" component={GraficoPage} />
				<Route path="/graficos/new" component={GraficoNew} />
				<Route path="/graficos/edit/:id" component={GraficoEdit} />

				{/* ImagemRoutes */}
				<Route exact path="/imagens" component={ImagemPage} />
				<Route path="/imagens/new" component={ImagemNew} />
				<Route path="/imagens/edit/:id" component={ImagemEdit} />

				{/* VideoRoutes */}
				<Route exact path="/videos" component={VideoPage} />
				<Route path="/videos/new" component={VideoNew} />
				<Route path="/videos/edit/:id" component={VideoEdit} />

				{/* ObjetoRoutes */}
				<Route exact path="/objetos" component={ObjetoPage} />
				<Route path="/objetos/new" component={ObjetoNew} />
				<Route path="/objetos/edit/:id" component={ObjetoEdit} />

				{/* TextoRoutes */}
				<Route exact path="/textos" component={TextoPage} />
				<Route path="/textos/new" component={TextoNew} />
				<Route path="/textos/edit/:id" component={TextoEdit} />

				{/* FormularioRoutes */}
				<Route exact path="/formularios" component={FormularioPage} />
				<Route path="/formularios/new" component={FormularioNew} />
				<Route path="/formularios/edit/:id" component={FormularioEdit} />

			</Switch>
		</Suspense>
	);
}
