import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export const regexEmail = /^[\w+.]+@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,})+$/;

export const createFormikValidation = (formMap, values) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key]) {
			errors[key] = "O campo " + value + " é obrigatório.";
			if (value == 'email') {
				errors[key] = "O campo e-mail é obrigatório.";
			}
		} else if ((key === "email" || key === "username") && !regexEmail.test(values[key])) {
			errors[key] = "O email informado é inválido.";
		} else if (key === "password" && values[key].length < 6) {
			errors[key] = "Use pelo menos 6 caracteres.";
		}
	}

	return errors;
};

export const shallowEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}

	return true;
};

export const formatBRL = (value) => {
	const formatter = new Intl.NumberFormat("pt-BR", {
		currency: "BRL",
		minimumFractionDigits: 2,
	});

	value = "R$ " + formatter.format(value);

	return value;
};

export const formatIntegerInput = input => {
	if (input.target.value == '') {
		input.target.value = '';
		return input;
	}

	input.target.value = Number(input.target.value.toString().replace(/[^0-9]/g, ''));
	return input;
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
	if (input.target.value.length > 8) {
		var cep = input.target.value.replace(/[^0-9]/, "");

		const instance = axios.create();

		instance
			.get(`https://viacep.com.br/ws/${cep}/json`, {})
			.then((res) => {
				if (res.data.erro) {
					setFieldValue(nomeEndereco, "");
					setFieldValue(nomeBairro, "");
					setFieldValue(nomeCidade, "");
					setFieldValue(nomeUf, "AC");
				} else {
					setFieldValue(nomeEndereco, res.data.logradouro);
					setFieldValue(nomeBairro, res.data.bairro);
					setFieldValue(nomeCidade, res.data.localidade);
					setFieldValue(nomeUf, res.data.uf);
				}
			})
			.catch((err) => { });
	}

	return formatCepInput(input);
};

export const formatCepInput = (input) => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");

	input.target.value = value;

	return input;
};

export const listaUfs = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"];

export const formatDataInput = (input) => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");

	input.target.value = value;

	return input;
};

export const formatDataCartaoInput = (input) => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");

	if (parseInt(value.substring(0, 2)) > 12) {
		value = "12" + value.substring(2);
	}

	value = value.replace(/(\d{2})(\d)/, "$1/$2");

	input.target.value = value;

	return input;
};

export const formatNumeroCartaoInput = (input) => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");

	const isAmex = /^3[47]\d{0,13}$/.test(value);

	if (isAmex) {
		value = value.replace(/(\d{4})(\d{6})(\d{0,5})/, "$1 $2 $3");
	} else {
		value = value.replace(/(\d{4})(\d{4})(\d{0,4})(\d{0,4})/, "$1 $2 $3 $4");
	}

	input.target.value = value.trim();

	return input;
};

export const formatarConteudo = (conteudo, tamanho) => {
	if (tamanho && conteudo) {
		if (conteudo.length > tamanho) {
			return `${conteudo.substring(0, tamanho)}...`;
		}
	}

	return conteudo;
};

export const enableLoading = (setLoading, setLoadingButtonStyle) => {
	setLoading(true);
	setLoadingButtonStyle({ paddingRight: "3.5rem" });
};

export const disableLoading = (setLoading, setLoadingButtonStyle) => {
	setLoading(false);
	setLoadingButtonStyle({ paddingRight: "2.5rem" });
};

export const formatData = (value) => {
	const data = value.split(" ")[0];

	return `${data.split("-")[2]}/${data.split("-")[1]}/${data.split("-")[0]}`;
};

export const validaCpf = (cpf) => {
	var add = 0;
	var rev = 0;

	cpf = cpf.replace(/[^\d]+/g, "");
	if (cpf == "") return false;

	if (cpf.length != 11) return false;

	for (var i = 0; i < 9; i++) {
		add += parseInt(cpf.charAt(i)) * (10 - i);
	}

	rev = 11 - (add % 11);

	if (rev == 10 || rev == 11) {
		rev = 0;
	}
	if (rev != parseInt(cpf.charAt(9))) {
		return false;
	}

	add = 0;

	for (var j = 0; j < 10; j++) {
		add += parseInt(cpf.charAt(j)) * (11 - j);
	}

	rev = 11 - (add % 11);

	if (rev == 10 || rev == 11) {
		rev = 0;
	}

	if (rev != parseInt(cpf.charAt(10))) {
		return false;
	}

	return true;
};

export const formatCPFInput = (input) => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatTelefoneInput = (input) => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{0})(\d)/, "$1($2");
	value = value.replace(/(\d{2})(\d)/, "$1) $2");
	value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const lista_formato_imagens = ["jpg", "png", "jpeg", "image/jpg", "image/png", "image/jpeg"];
export const lista_formato_videos = ["mp4", "avi", "video/mp4", "video/avi"];

export const returnType = (name) => {
	const name_array = name.split(".");
	const type = name_array[name_array.length - 1];

	return type;
};

export const primeiraMaiuscula = (value) => {
	return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const etapaPorcentagem = (data_inicio, data_fim, status = null) => {
	const agora = moment().format("YYYY-MM-DD");

	if (moment(data_fim).isSameOrBefore(moment(data_inicio)) || moment(agora).isSameOrAfter(moment(data_fim)) || status === "Finalizado") {
		return 100;
	}

	if (moment(agora).isSameOrBefore(moment(data_inicio))) {
		return 0;
	}

	const tempo_obra = moment(data_inicio).diff(moment(data_fim), "days");

	return (moment(data_inicio).diff(moment(agora), "days") / tempo_obra) * 100;
};

export const getCardFlag = (numero) => {
	var card = numero?.replace(/[^0-9]+/g, "");

	var cards = {
		Visa: /^4[0-9]{12}(?:[0-9]{3})/,
		Master: /^5[1-5][0-9]{14}/,
		Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
		Amex: /^3[47][0-9]{13}/,
		Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
		Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
		Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
		JCB: /^(?:2131|1800|35\d{3})\d{11}/,
		Aura: /^(5078\d{2})(\d{2})(\d{11})$/,
	};

	for (var flag in cards) {
		if (cards[flag].test(card)) {
			return flag;
		}
	}

	return false;
};

export const mostrarAlerta = (data, history, Swal) => {
	if (data.length <= 0) {
		Swal.fire({
			title: "Ops!",
			text: "É necessária a criação de ao menos um Canal antes de continuar",
			icon: "info",
			showCancelButton: true,
			confirmButtonText: "Criar Canal",
			cancelButtonText: "Voltar",
		}).then((result) => {
			if (result.isConfirmed) {
				history.push("/dashboard/canais");
			} else {
				history.push("/dashboard/projetos");
			}
		});
	}
};

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;