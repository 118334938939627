import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";

export default class BetterCircularProgress extends Component {
    render() {
        return (
            <div className={`w-100 h-100 ${this.props.className ?? ''}`}>
                {this.props.loading ? (
                    <div className="d-flex justify-content-center" style={{ textAlign: "center" }}>
                        <div style={{ marginTop: "15vh", marginBottom: "15vh" }}>
                            <CircularProgress color="inherit" style={{ color: "#223d48" }} size={50} />
                        </div>
                    </div>
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}
