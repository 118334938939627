import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { getListaSolicitacoes } from "../../crud/user.crud";

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
		width: '200px'
	},
})((props) => (
	<MuiMenu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

export default function Header({ children }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const user = useSelector((state) => state.auth.user);
	const [urlAvatar, setUrlAvatar] = useState('/images/no-user.png');
	const [novaNotificacao, setNovaNotificacao] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		if (user && user.imagem?.url) {
			setUrlAvatar(user.imagem.url);
		}
	}, [user.imagem?.url]);

	useEffect(() => {
		getListaSolicitacoes().then((res) => {
			const notif = res.data;

			if (notif.canais.length > 0 || notif.contatos_solicitados.length > 0 || notif.documentos.length > 0) {
				setNovaNotificacao(true);
			}
		})
	}, [])

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div
			style={{ height: '71px', display: 'flex', flexDirection: 'row', padding: '16px 24px', flex: 1, borderBottom: '1px solid #DEE0E8', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#fff', position: 'sticky', top: '0', zIndex: '9' }}>
			<p style={{ fontFamily: 'Poppins, Helvetica, sans-serif !important', fontWeight: 400, fontSize: 14, lineHeight: '21px', marginBottom: 0 }}>Olá, {user.usr_nome}!</p>

			<div
				aria-controls="customized-menu"
				aria-haspopup="true"
				variant="contained"
				color="primary"
				onClick={handleClick}
				style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
				<div style={{ marginRight: '16px' }}>
					<p style={{ fontFamily: 'Poppins, Helvetica, sans-serif !important', fontWeight: 500, fontSize: 14, lineHeight: '21px', marginBottom: 0 }}>{user.usr_nome}</p>
					<p style={{ fontFamily: 'Poppins, Helvetica, sans-serif !important', fontWeight: 400, fontSize: 12, lineHeight: '18px', marginBottom: 0, textAlign: 'right' }}>Admin</p>
				</div>

				<div style={{ backgroundImage: `url(${urlAvatar})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '32px', height: '32px', borderRadius: '50px' }}></div>
			</div>

			<StyledMenu
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<Link to={`/user/show/${user.id}`}  >
					<MuiMenuItem>
						Perfil
					</MuiMenuItem>
				</Link>

				<Link to={`/user/solicitacoes`} onClick={() => setNovaNotificacao(false)}>
					<MuiMenuItem>
						Solicitações
						{novaNotificacao == true ? (
							<div style={{ width: '8px', height: '8px', borderRadius: '8px', backgroundColor: '#f8b133', marginLeft: '8px' }}></div>
						) : null}
					</MuiMenuItem>
				</Link>

				<Link to="/logout"  >
					<MuiMenuItem>
						Sair
					</MuiMenuItem>
				</Link>
			</StyledMenu>
		</div>
	);
}
