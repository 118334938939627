import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';


const StyledTextField = withStyles({
	root: {
		// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
		// borderRadius: 3,
		// border: 0,
		// color: 'white',
		// height: 48,
		// padding: '0 30px',
		// boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		'& .MuiOutlinedInput-root': {
			height: '40px',
			borderRadius: '20px',
			'& input': {
				padding: '12px'
			},
			'& fieldset': {
				// borderColor: '#F8B133',
				padding: '12px',
				'& legend': {
					'& span': {
						color: '#F8B133',
					},
					// color: '#F8B133',
				},
			},
			// '&:hover fieldset': {
			// 	borderColor: '#F8B133',
			// },
			'&.Mui-focused fieldset': {
				borderColor: '#F8B133',
			},
		},

		'& .MuiInputLabel-root': {
			'&.Mui-focused': {
				color: '#F8B133',
			},
		},
	},
	label: {
		// textTransform: 'capitalize',
	},
})(TextField);


export default class HeaderSearch extends Component {
	render() {
		return (
			<StyledTextField variant="outlined"  {...this.props} size="small"
				InputProps={{
					startAdornment: <i style={{ color: '#53545C' }} className={`color-gray-500 fas fa-search`}></i>,
				}} />
		);
	}
}

// export default class BetterTextField extends Component {
// 	render() {
// 		return (
// 			<TextField {...this.props} />
// 		);
// 	}
// }
